import React from 'react';
import { Col, Row } from 'components/Core/Grid';
import RichText from 'components/RichText';
import WebsiteImage from 'components/WebsiteImage';
import ContentfulCallToAction from 'components/Contentful/ContentfulCallToAction';
import { StyledHero } from '../styles';

const Hero = ({ data, heroImg, wrench }) => {
  const title = JSON.parse(data?.heading1?.raw)?.content[0].content[0].value;

  return (
    <StyledHero heroImg={heroImg}>
      <Row type="flex" justify="center">
        <WebsiteImage loading="eager" className="wrench-image" image={wrench} />
        <Col sm={22} lg={18} mdOffset={6}>
          <h1>{title}</h1>
          <RichText content={data?.subhead} />
          <ContentfulCallToAction
            {...data.contextualCta?.[0]}
            className="playbook-hero-btn"
            dataPosition="contractor playbook hero"
          />
        </Col>
      </Row>
    </StyledHero>
  );
};

export default Hero;
