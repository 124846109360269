import React from 'react';
import ChapterCard from '../ChapterCard';
import { PlaybookHomeLayout } from '../styles';
import HyperLink from 'components/Core/HyperLink';

const Chapters = ({ chapters, progress }) => {
  return (
    <PlaybookHomeLayout backgroundColor="Skyline 1" id="playbook-content">
      {chapters.map((chapter, index) => {
        let lastVisitedSection = progress[index].findIndex((section) => section === 0);
        lastVisitedSection = lastVisitedSection < 0 ? 0 : lastVisitedSection;
        return (
          <HyperLink
            href={chapter?.sections[lastVisitedSection]?.seo?.pageUrl}
            id={`chapter${index + 1}`}
            key={`chapter-${index}`}
          >
            <ChapterCard
              id={index + 1}
              key={index}
              chapterNumber={index + 1}
              chapter={chapter}
              chapterProgress={progress[index]}
            />
          </HyperLink>
        );
      })}
    </PlaybookHomeLayout>
  );
};

export default Chapters;
