import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';

export const PlaybookHomeLayout = styled(SectionLayout)`
  position: relative;
  z-index: 10;

  .section-layout--inner-container {
    max-width: 840px !important;
    padding-top: 50px;
  }
`;

export const StyledHero = styled(SectionLayout)`
  text-align: left;
  position: relative;
  background-image: ${(props) => `url(${props.heroImg})`};
  background-size: cover;
  background-position: bottom center;
  min-height: 93vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  padding-top: 200px;

  h1 {
    color: var(--white);
    padding-bottom: 10px;
  }
  p {
    color: var(--white);
    max-width: 880px !important;
    font-size: var(--font-size-p);
  }
  .playbook-hero-btn {
    margin-top: 20px;
  }
  .section-layout--inner-container {
    max-width: 960px !important;
    margin: auto;
  }

  .wrench-image {
    max-height: 1100px;
    position: absolute;
    bottom: -400px;
    left: -200px;
  }

  @media (max-width: 1199px) {
    padding: 100px 0 0 0;
  }

  @media (max-width: 991px) {
    .wrench-image {
      display: none;
    }
  }

  @media (max-width: 767px) {
    background-position: bottom right;
    background-size: cover;
    padding: 20px 0 0 0;
    h1 {
      margin-top: 50px;
    }
  }
`;

export const StyledChapter = styled.div`
  margin-bottom: 20px !important;

  padding: 24px 30px !important;
  background-color: ${(props) => props.backgroundColor} !important;
`;

export const ChapterKicker = styled.p`
  color: var(--grey-6);
  font-size: var(--font-size-13);
  letter-spacing: 0.5px;
  margin-bottom: 8px !important;
  span {
    text-align: right;
    font-weight: bold;
    color: var(--titan-blue-3);
    float: right;
    svg {
      margin-left: 6px;
    }
  }
`;

export const ChapterTitle = styled.h2`
  margin-bottom: 14px !important;
  font-size: var(--font-size-28) !important;
`;

export const ChapterSections = styled.p`
  display: flex;
  flex-direction: row;
  margin-bottom: 14px !important;
  .section-status {
    margin-right: 14px;
    height: 24px;
    width: 24px;
    @media (max-width: 575px) {
      flex: 1;
    }
  }
  a {
    color: var(--dark) !important;
    @media (max-width: 575px) {
      flex: 11;
    }
  }
  a:hover {
    color: black !important;
  }
`;
