import React from 'react';
import HyperLink from 'components/Core/HyperLink';
import { ArrowRightIcon } from 'components/Brand/Icons';
import { STIcon } from 'components/Brand/Icons/STIcon';
import WebsiteImage from 'components/WebsiteImage';
import { ChapterKicker, ChapterSections, ChapterTitle, StyledChapter } from '../styles';

const ChapterCard = ({ chapter, chapterNumber, chapterProgress }) => {
  const count = chapterProgress.reduce((x, y) => x + y);
  const label =
    count === 0 ? 'Get Started' : count === chapterProgress.length ? 'Read Again' : 'Continue';
  return (
    <StyledChapter
      bordered={false}
      backgroundColor={label === 'Read Again' ? '#F6F8FA' : 'var(--white)'}
    >
      <ChapterKicker>
        CHAPTER {chapterNumber}
        <span>
          {label}
          <ArrowRightIcon />
        </span>
      </ChapterKicker>
      <ChapterTitle>{chapter.title}</ChapterTitle>
      {chapter.sections.map((page, index) => {
        const { seo, section } = page;
        return (
          <ChapterSections key={index} fontSize="16px">
            <STIcon
              className="section-status"
              fill="var(--titan-blue-3)"
              alt="playbook-section-status"
              type={chapterProgress[index] ? 'check-circle' : 'checkbox-blank-circle-outline'}
            />
            <HyperLink href={seo?.pageUrl}>{section[0].title}</HyperLink>
          </ChapterSections>
        );
      })}
    </StyledChapter>
  );
};

export default ChapterCard;
