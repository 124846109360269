const Cookies = require('js-cookie');

export const initPlaybookProgress = (playbookContentfulId, chapters) => {
  const progress = [];
  chapters.map((ch, ch_index) => {
    const s_progress = [];
    ch.sections.map((sect, s_index) => {
      s_progress[s_index] = 0;
    });
    progress[ch_index] = s_progress;
  });
  Cookies.set(playbookContentfulId, JSON.stringify(progress));
  return progress;
};

export const updatePlaybookProgress = (playbookContentfulId, chapters, chapter, section) => {
  if (Cookies.get(playbookContentfulId)) {
    const newProgress = JSON.parse(Cookies.get(playbookContentfulId));
    newProgress[chapter][section] = 1;
    Cookies.set(playbookContentfulId, JSON.stringify(newProgress));
  } else {
    initPlaybookProgress(playbookContentfulId, chapters);
  }
};
