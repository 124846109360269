import React from 'react';
import { graphql } from 'gatsby';
import Cookies from 'js-cookie';
import Hero from 'components/Page/Playbook/Hero';
import Chapters from 'components/Page/Playbook/Chapters';
import { initPlaybookProgress } from 'components/Page/Playbook/utils/mapData';
import ToolboxLayout from 'layouts/toolboxLayout';

const Playbook = (props) => {
  const { playbookContentfulId } = props.pageContext;
  const seoData = props.data?.contentfulPage?.seo;
  const chapters = props.data?.contentfulPage?.components?.filter(
    (component) =>
      component?.__typename !== 'ContentfulDynamicHero' && Object.keys(component).length !== 0,
  );
  const hero = props.data?.contentfulPage?.components?.find(
    (component) => component?.__typename === 'ContentfulDynamicHero',
  );

  const playbookProgress = Cookies.get(playbookContentfulId)
    ? JSON.parse(Cookies.get(playbookContentfulId))
    : initPlaybookProgress(playbookContentfulId, chapters);

  const pageImages = props.data.allContentfulImage?.edges;

  const findImage = (internalName) =>
    pageImages.find((i) => i.node.internalName === internalName)?.node?.image?.file?.url;

  const heroBackground = findImage('contractor playbook hero');
  const wrench = findImage('wrench-cutout');

  return (
    <ToolboxLayout
      theme="TRANS_BG_WHITE_COLOR"
      contentfulSeo={seoData}
      {...props}
      hero
      stickyHeader
    >
      {hero && <Hero heroImg={heroBackground} wrench={wrench} data={hero} />}
      <Chapters chapters={chapters} progress={playbookProgress} />
    </ToolboxLayout>
  );
};
export default Playbook;

export const PlaybookSplashQuery = graphql`
  query PlaybookSplashQuery($playbookContentfulId: String!) {
    contentfulPage(contentful_id: { eq: $playbookContentfulId }) {
      updatedAt
      seo {
        ...Seo
      }
      components {
        ... on ContentfulContentBlock {
          title: heading
          description: subhead {
            subhead
          }
          sections: collection {
            ... on ContentfulPage {
              seo {
                ...Seo
              }
              section: components {
                ... on ContentfulRichText {
                  title: sectionTitle
                  slug: anchorId
                }
              }
            }
          }
        }
        ...DynamicHero
      }
    }
    allContentfulImage(
      filter: {
        contentful_id: {
          in: [
            "zINh8e6Cw1eS2SwVfCdK9" #contractor playbook hero
            "56ieVipqfuwYPByTWmBZsY" #wrench-cutout
          ]
        }
      }
    ) {
      edges {
        node {
          internalName
          image {
            ...Image
          }
        }
      }
    }
  }
`;
